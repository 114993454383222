<template lang="pug">
  include ../../assets/pug/index
  div
    +h1('ownerInfo')
    +h2('ownerInfoText')
    +inputValidation('ownerID', 'passport', '["required", "length"]').v-style-input
    .test-content__action
      +btn('primary', 'btn.back')(@click="validation(false)")
      +btn('primary', 'btn.next')(@click="validation(true)")
</template>

<script>
import { validate, validationField } from '@/mixins/validation'
import { mapActions } from 'vuex'

export default {
  name: 'ownerID',
  data () {
    return {
      ownerID: null,
      validationField
    }
  },
  validations: validate.ownerID,
  methods: {
    ...mapActions(['stepControl', 'setOwnerID']),
    validationFieldsRequired(field) {
      return this.$validate.required(field)
    },
    validation (step) {
      this.$v.$touch()
      !this.$v.$invalid && step && this.stepControl(step) && this.setOwnerID(this.ownerID)
      !step && this.stepControl(step)
    }
  }
}

</script>

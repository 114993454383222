<template lang="pug">
  include ../../assets/pug/index
  div
    +h1('choiceStand')
    +h2('title.choiceStand')
    div
      +autocomplete('standID', 'stands', 'name', 'id', 'choiceStand', '["required"]')
      //- +input('declarationsID', 'enterDeclarationNum').mt-4
      v-text-field(v-model.trim="declarationsID" :label="$t('enterDeclarationNum')").mt-4
    .test-content__action.justify-end
      +btn('primary', 'btn.next')(@click="validation()")
</template>

<script>
import { validate, validationField } from '@/mixins/validation'
import { mapState, mapActions, mapMutations } from 'vuex'

export default {
  name: 'getStand',
  data() {
    return {
      standID: '',
      declarationsID: '',
      validationField
    }
  },
  validations: validate.standID,
  computed: {
    ...mapState({
      stands: state => state.stand.standData.stands
    })
  },
  watch: {
    stands (value) {
     value && this.setNameToStands(value)
    }
  },
  methods: {
    ...mapActions(['stepControl', 'getVehicleFromId']),
    ...mapMutations(['SET_DATA_MEASUREMENT', 'SET_DATA_MEASUREMENT_NESTING']),

    stepoverToEnd () {
      let lastStep = 6
      this.SET_DATA_MEASUREMENT({ prop: 'standID', value: this.standID })
      this.SET_DATA_MEASUREMENT({ prop: 'declaration', value: this.declarationsID })
      this.SET_DATA_MEASUREMENT({ prop: 'step', value: lastStep})
      this.SET_DATA_MEASUREMENT({ prop: 'isSteppedToEnd', value: true })
    },

    setId (data) {
      this.SET_DATA_MEASUREMENT_NESTING({ prop: 'vehicleObj', nesting: 'id', value: data.vehicle_id })
      this.SET_DATA_MEASUREMENT_NESTING({ prop: 'ownerObj', nesting: 'id', value: data.owner_id })
    },

    getDeclaration () {
      let params = new URLSearchParams()
      params.set('number', this.declarationsID)
      return this.$api.get(`stand/declaration?${params}`).then((r) => {
        r?.data?.vehicle_id && this.setId(r.data)
        return r?.status
        })
    },

    checkVehicle () {
      this.getVehicleFromId().then(() => this.stepoverToEnd())
    },

    checkDeclaration () {
      this.getDeclaration()
        .then((r) => r ?? this.$notification.error(this.$i18n.t('declarationNotFound')))
        .then(r => r === 'success' && this.checkVehicle())
    },

    stepToNext () {
      this.$v.$touch()
      !this.$v.$invalid && this.stepControl(true) && this.SET_DATA_MEASUREMENT({ prop: 'standID', value: this.standID })
    },

    setNameToStands (stands) {
      stands.forEach(item => {
        !item.name && this.$set(item, "name", this.$i18n.t('noName'))
      })
    },

    validation () {
      (this.declarationsID && this.standID) ?
        this.checkDeclaration() :
        this.stepToNext()
    },
  },
  mounted () {
    this.stands?.length && this.setNameToStands(this.stands)
    this.$store.commit('setDataTitle', this.$i18n.t('conductTesting'))
  },
}

</script>

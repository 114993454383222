<template lang="pug">
  include ../../assets/pug/index
  .card
    .mb-5.d-flex.flex-wrap(style="row-gap: 20px")
      v-btn(
        v-for="(shaft, index) in shafts"
        :key="index"
        @click="changeAxis(index)"
        :disabled="actions.isActive"
        :class="['mr-3', { 'success': numberChassis === index }, { 'primary': isDoneShafts.includes(String(index)) }]"
      )
        img.mr-3(
          v-if="isDoneShafts.includes(String(index))"
          src="@/assets/images/icons/icon-check.svg"
        )
        span {{ index === 0 ? $t('handBrake') : $t('shaft') + ` ${index}` }}
      router-link(
        v-if="isDoneShafts.length === shafts.length"
        to="/history"
      )  {{ $t('goToDeclaration') }}
        <!-- :to="{ name: 'declarationDocument', params: { number: declaration }}">				 -->
    .mb-6(v-if="isDoneShafts.length === shafts.length")
      a(href="#" @click="SET_DATA_MEASUREMENT({ prop: 'step', value: 1 })") {{ $t('testAnotherCar') }}
    p.mb-1 {{ $t('title.startTestInfoText') }}
    .startTest(:class="[{ 'approved': actions.isReady }, { 'testing': actions.isTesting } ]")
      WheelTwistAnimated(left :brakeForse="measurement.measurements && measurement.measurements[0].left_brake_force")
      .startTest-graph
        .startTest-graph__title 0
        .startTest-graph__left
          span(style="max-height: 0%")
        .startTest-graph__number
          ul
            template
              li(v-for="(scale, index) in scales" :key="index") {{scale}}
        .startTes3t-graph__right
          span(style="max-height: 0%")
        .startTest-graph__weight {{ weight.toFixed(2) }} {{$t('unitWeight')}}
      WheelTwistAnimated(:brakeForse="measurement.measurements && measurement.measurements[0].right_brake_force")
    .d-flex.justify-center(style="flex-direction: column")
      p(v-if="isDoneShafts.includes(String(numberChassis))") {{ $t('title.startTestAgain') }}
      v-btn.success.mb-2(
        v-if="actions.isStatus"
        @click="connectStand()"
        :disabled="actions.isActive"
        :loading="getLoadingByName('BTN-checkStatusStandInfo')"
      ) {{ $t('btn.connectToStand') }}
      v-btn.success.mb-2(
        v-if="actions.isReady"
        @click="checkShaftAndStartMeasurement"
        :disabled="actions.isActive"
        :loading="getLoadingByName('BTN-startTest')"
      ) {{ $t('btn.startTest') }}
      v-btn.error.mb-2(
        v-if="actions.isTesting"
        @click="stopTesting"
      ) {{ $t('btn.stopTest') }}

      v-dialog(
        v-model="confirmDialog"
        persistent
        max-width="500px")
        v-card
          v-card-title.justify-center {{ $t('reMeasure') }}
          v-card-actions.justify-center
            +btn('success', 'btn.yes')(@click="confirmDialog = false; startMeasurement()")
            +btn('primary', 'btn.no')(@click="confirmDialog = false")

    .startTest-status(v-if="actions.isTesting")
      .startTest-progress
        span(:style="`max-width: calc(8.33 * ${stage.length}%)`")
        <!-- was 8,3  changed to 7.7 -->
        b {{ progressAmount }} %
      .startTest-status__terminal(v-if="stage.length")
        p(v-for="(e, index) in stage" :key="index") {{ e }}
    .measurement-graph
      graph-line(
        v-if="isDoneShafts.includes(String(numberChassis))"
        :height="300"
        :labels="Array.apply(null, {length: 2049}).map(Number.call, Number)"
        :names="names"
        :values="graph"
      )
        legends(:names="names")
        tooltip(:names="names" :position="'right'")
        guideline(:tooltip-x="true" :tooltip-y="true")
</template>

<script>
import {mapState, mapActions, mapGetters, mapMutations} from 'vuex'
import WheelTwistAnimated from '@/components/modules/WheelTwistAnimated.vue'
export default {
  name: '',
  components: {
    WheelTwistAnimated
  },
  props: {
    // status: String,
    countAxis: Number
  },
  data () {
    return {
      measurement: {},
      isDoneShafts: [],
      names: [ this.$i18n.t('leftShaft'), this.$i18n.t('rightShaft') ],
      graph: [],
      shafts: [],
      confirmDialog: false
    }
  },
  computed: {
    ...mapGetters(['getLoadingByName']),
    ...mapState({
      actions: state => state.measurement.actions,
      status: state => state.measurement.status,
      measurements: state => state.measurement.measurement,
      weight: state => state.measurement.weight,
      numberChassis: state => state.measurement.numberChassis,
      stage: state => state.measurement.stage,
      declaration: state => state.measurement.declaration
    }),
    progressAmount () {
      const RATE = 8.3
      const result = (RATE * this.stage.length).toFixed()
      return result < 100 ? result : 100
    },
    scales () {
      let scales = []
      for (let i = 0; i <= 30; i += 3) scales.push(i)
      return scales.reverse()
    }
  },
  methods: {
    ...mapActions(['createDeclaration', 'changeNumberChassis', 'checkStatus', 'startMeasurement', 'connectStand']),
    ...mapMutations(['SET_DATA_MEASUREMENT']),
    checkShaftAndStartMeasurement () {
      if (!this.isDoneShafts.includes(String(this.numberChassis))) {
        this.startMeasurement()
      } else {
        this.confirmDialog = true
      }
    },
    setMeasurement (item, shaft) {
      this.measurement = item
      this.graph = []
      this.graph.push(shaft.left_brake_forces)
      this.graph.push(shaft.right_brake_forces)
      !this.isDoneShafts.includes(shaft.axis) && this.isDoneShafts.push(shaft.axis)
    },
    checkMeasurement () {
      for (let item of this.measurements) {
        let shaft = item.measurements[0]
        if (shaft.axis === String(this.numberChassis)) {
          this.setMeasurement(item, shaft)
          break
        } else this.measurement = {}
      }
    },
    changeAxis (id) {
      this.changeNumberChassis(id)
      this.checkMeasurement()
      this.checkStatus()
    },
    setCountAxis () {
      let step = 0
      for (step = 0; step < (this.countAxis + 1); step++) {
        this.shafts.push('')
      }
    },
    stopTesting () {
      this.$store.commit('SET_ACTIONS_STATUS', { prop: 'isTesting', type: false })
      this.$store.commit('SET_ACTIONS_STATUS', { prop: 'isStatus', type: true })
    }
  },
  watch: {
    measurements (value) {
      value && this.checkMeasurement()
    }
  },
  mounted () {
    this.checkStatus()
    this.checkMeasurement()
    this.setCountAxis()
  }
}
</script>

<template lang="pug">
  .test
    .test-step
      ul
        li(:class="{ active: step === 1, full: step > 1 }")
          a(href="#")
            i
              span 1
            i.icon-checkmark
            span {{ $t('chooseStand') }}
        li(:class="{ active: [2, 3].includes(step), full: step > 3 }")
          a(href="#")
            i
              span 2
            i.icon-checkmark
            span {{ $t('vehicleInfo') }}
          ul
            li(:class="{ active: step === 2 }") {{ $t('chassisNumber') }}
            li(:class="{ active: step === 3 }") {{ $t('vehicleInfo') }}
        li(:class="{ active: [4, 5].includes(step), full: step > 5 }")
          a(href="#")
            i
              span 3
            i.icon-checkmark
            span {{ $t('ownerInfo') }}
          ul
            li(:class="{ active: step === 4 }") {{ $t('itnEDRPOU') }}
            li(:class="{ active: step === 5 }") {{ $t('ownerInfo') }}
        li(:class="{ active: step === 6, full: step > 6 }")
          a(href="#")
            i
              span 4
            i.icon-checkmark
            span {{ $t('start') }}
        li
          a(href="#")
            i
              span 5
            span {{ $t('result') }}
    .test-content
      .test-content__card
        TestChoiceStand(v-if="step === 1")
        TestVehicleVIN(v-if="step === 2")
        TestVehicleInfo(v-if="step === 3")
        TestOwnerID(v-if="step === 4")
        TestOwnerInfo(v-if="step === 5")
        TestMeasurement(v-if="step === 6")
        //- StartTest(v-if="step === 6")
</template>

<script>
import TestChoiceStand from './TestChoiceStand'
import TestVehicleVIN from './TestVehicleVIN'
import TestVehicleInfo from './TestVehicleInfo'
import TestOwnerID from './TestOwnerID'
import TestOwnerInfo from './TestOwnerInfo'
import TestMeasurement from './TestMeasurement'

import { mapState } from 'vuex'

export default {
  name: 'test',
  components: {
    TestChoiceStand,
    TestOwnerID,
    TestOwnerInfo,
    TestVehicleVIN,
    TestVehicleInfo,
    TestMeasurement,
  },
  computed: {
    ...mapState({
      step: state => state.measurement.step
    })
  }
}

</script>
<style scoped>
 .test-step li > a {
   cursor: auto;
 }
</style>

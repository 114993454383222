<template lang="pug">
  include ../../assets/pug/index
  div
    +h1('ownerInfo')
    +h2('ownerInfoText')
    .d-flex
      +inputValidation('ownerObj.name', 'fullName', '["required"]').mr-3
      +input('ownerObj.owner_code', 'itnEDRPOU')(disabled).ml-3
    .test-content__action
      +btn('primary', 'btn.back')(@click="validation(false)")
      +btn('primary', 'btn.next')(@click="validation(true)")
</template>

<script>
import { validate, validationField } from '@/mixins/validation'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'ownerInfo',
  data() {
    return {
      find: false,
      validationField
    }
  },
  validations: validate.ownerObj,
  computed: {
    ...mapState({
      ownerObj: state => state.measurement.ownerObj
    }),
  },
  watch: {
    ownerObj (value) {
      value.id && (this.find = true)
    }
  },
  methods: {
    ...mapActions(['stepControl', 'setOwner']),
    validation (step) {
      this.$v.$touch()
      !this.$v.$invalid && step && !this.find && this.setOwner('post') && this.stepControl(step)
      !this.$v.$invalid && step && this.find && this.setOwner('put') && this.stepControl(step)
      !step && this.stepControl(step)
    }
  }
}

</script>

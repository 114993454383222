<template lang="pug">
  include ../../assets/pug/index
  div
    h1.mb-5
      +b('declaration')
      span.ml-3 {{ declaration }}
    Stand(:countAxis="vehicleObj.axis_number")
</template>

<script>
import Stand from '@/components/layouts/Stand.vue'
import { mapState, mapActions, mapMutations } from 'vuex'

export default {
  name: 'measurementStand',
  components: {
    Stand
  },
  computed: {
    ...mapState({
      actions: state => state.measurement.actions,
      declaration: state => state.measurement.declaration,
      vehicleObj: state => state.measurement.vehicleObj,
      isSteppedToEnd: state => state.measurement.isSteppedToEnd
    })
  },
  methods: {
    ...mapActions(['createDeclaration','changeNumberChassis', 'checkStatus']),
    ...mapMutations(['SET_DATA_MEASUREMENT']),

  },
  mounted () {
    this.isSteppedToEnd && this.createDeclaration()
    this.checkStatus()
  },
  destroyed () {
    this.SET_DATA_MEASUREMENT({ prop: 'isSteppedToEnd', value: false })
  }
}
</script>

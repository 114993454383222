<template lang="pug">
  include ../../assets/pug/index
  div
    +h1('vehicleInfo')
    +h2('title.vehicleInfoText')
    div(v-if="find").testInfo-btnEdit
      v-icon(@click="edit = !edit" :color="getPencilColor") mdi-pencil
    .testInfo
      .testInfo-item
        +autocomplete('vehicleObj.manufacturer_id', 'stand.standData.manufacturers', 'name', 'uid', 'carManufacturer', '["required"]')(@input="getModel(vehicleObj.manufacturer_id)" :disabled="find && (hasMark || !edit)")
      .testInfo-item
        +autocomplete('vehicleObj.model_id', 'stand.model', 'name', 'uid', 'carModel', '["required"]')(:disabled="find && (hasModel || !edit)")
      .testInfo-item
        +autocomplete('vehicleObj.vehicle_type_id', 'stand.standData.vehicle_types', 'name', 'uid', 'vehicleType', '["required"]')(:disabled="find && (hasVehicleType || !edit)")
      .testInfo-item
        +autocomplete('vehicleObj.category_id', 'stand.standData.categories', 'name', 'uid', 'category', '["required"]')(:disabled="find")
      .testInfo-item
        +autocomplete('vehicleObj.issue_year', 'getYears', '', '', 'graduationYear', '["required"]')(:disabled="find")
      .testInfo-item
        +autocomplete('vehicleObj.axis_number', '[2,3,4,5,6,7,8]', '', '', 'numberShaft', '["required"]')(:disabled="find")
      .testInfo-item
        +inputValidation('vehicleObj.mass', 'vehicleWeight', '["required"]')(:disabled="find" type="number" :suffix="`${$t('unitWeight')}.`")
      .testInfo-item
        +autocomplete('vehicleObj.fuel_type_id', 'stand.standData.fuel_types', 'name', 'uid', 'fuelType', '["required"]')(:disabled="find && !edit")
      .testInfo-item
        +inputValidation('vehicleObj.car_license_plate', 'registrationNumber', '["required", "length", "LICENSE_PLATE_REGEX"]')(:disabled="find && !edit" @blur="licensePlateUpper()" maxLength="8")
      .testInfo-item
        +inputValidation('vehicleObj.engine_volume', 'engineCapacity', '["required"]')(:disabled="find && !edit" type="number" :suffix="`${$t('unitVolume')}³`")
      .testInfo-item
        +autocomplete('vehicleObj.color_id', 'stand.standData.colors', 'name', 'uid', 'color', '["required"]')(:disabled="find && !edit")
      .testInfo-item
        +inputValidation('vehicleObj.odometr', 'odometer', '["required"]')(:suffix="$t('unitKilometer')")

    .test-content__action
      +btn('primary', 'btn.back')(@click="validation(false)")
      +btn('primary', 'btn.next')(@click="validation(true)")

</template>

<script>
import { validate, validationField } from '@/mixins/validation'
import { mapState, mapActions, mapMutations } from 'vuex'

export default {
  name: 'vehicleInfo',
  data() {
    return {
      find: false,
      edit: false,
      hasModel: true,
      hasMark: true,
      hasVehicleType: true,
      validationField
    }
  },
  validations: validate.vehicleObj,
  computed: {
    ...mapState({
      stand: state => state.stand,
      vehicleObj: state => state.measurement.vehicleObj,
      getPencilColor () {
        return this.edit ? 'primary' : ''
    }
    }),
    getYears () {
      let years = [];
      let currentYear = new Date().getFullYear()
      for (let i = currentYear; i >= 1980; i--) {
        years.push(i)
      }
      return years
    },
  },
  watch: {
    vehicleObj (value) {
      value.id && (this.find = true)
      value.model_id === null && (this.hasModel = false)
      value.manufacturer_id === null && (this.hasMark = false)
      value.vehicle_type_id === null && (this.hasVehicleType = false)

    }
  },
  methods: {
    ...mapActions(['stepControl', 'setVehicle', 'updateVehicle', 'getModel']),
    ...mapMutations(['SET_DATA_MEASUREMENT_NESTING']),
    validation (step) {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.SET_DATA_MEASUREMENT_NESTING({ prop: 'vehicleObj', nesting: 'odometr', value: this.vehicleObj.odometr })
        if (!this.find) {
          this.setVehicle('post')
          this.stepControl(step)
        } else {
          this.setVehicle('put')
          this.stepControl(step)
        }
      }
      !step && this.stepControl(step)
    },
    licensePlateUpper () {
      this.vehicleObj.car_license_plate = this.vehicleObj.car_license_plate.toUpperCase()
    }
  },
}
</script>

<template lang="pug">
  include ../../assets/pug/index
  div
    +h1('chassisNumber')
    +h2('title.chassisNumber')
    +inputValidation('numberChassis', 'chassisNumber', '["required", "length"]').v-style-input
    .test-content__action
      +btn('primary', 'btn.back')(@click="validation(false)")
      +btn('primary', 'btn.next')(@click="validation(true)")
</template>

<script>
import { validate, validationField } from '@/mixins/validation'
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
  name: 'getStand',
  data() {
    return {
      numberChassis: null,
      validationField
    }
  },
  validations: validate.numberChassis,
  computed: {
     ...mapState({
      vehicleObj: state => state.measurement.vehicleObj
    })
  },
  methods: {
    ...mapActions(['stepControl', 'checkVehicle', 'getModel']),
    ...mapMutations(['SET_DATA_MEASUREMENT_NESTING']),
    validation (step) {
      this.$v.$touch()
      if (!this.$v.$invalid && step) {
        this.stepControl(step)
        this.SET_DATA_MEASUREMENT_NESTING({ prop: 'vehicleObj', nesting: 'vin', value: this.numberChassis })
        this.checkVehicle().then(() => this.vehicleObj.manufacturer_id && this.getModel(this.vehicleObj.manufacturer_id))
      }
      !step && this.stepControl(step)
    },
  }
}

</script>

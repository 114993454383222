<template lang="pug">
  .startTest-wheel
    .startTest-wheel__caption {{ shaft }}
    .startTest-wheel__title {{brakeForse && brakeForse.toFixed(2) + $t('unitKN') }}
    .startTest-wheel__twist.startTest-wheel__twist-left
      span
    .startTest-wheel__shaft
    .startTest-wheel__speed 3{{$t('unitKmYear')}}
</template>
<script>
export default {
  props: {
    brakeForse: Number,
    left: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    shaft () {
      return this.left ? this.$i18n.t('leftShaft') : this.$i18n.t('rightShaft')
    }
  }
}
</script>